import React from "react"
import Computer from "../../img/PcWithDash-ani-css.svg"

export default function MainSection() {
  return (
    <div>
      <div className="home-main">
        <div
          data-sal="slide-right"
          data-sal-delay="100"
          data-sal-easing="ease"
          data-sal-duration="1200"
        >
          <h2 className="main-brand">YOUR SYSTEM DONE&nbsp;RIGHT</h2>
          <p className="sub-brand">
            Transform your daily business routines into a robust automated
            system; Streamline your business process; Increase your staff's
            productivity; All combined in one operational SYSTEM DONE-RIGHT.
          </p>
          <a href="#contact" className="btn btn-primary">
            CONNECT WITH US
          </a>
        </div>
        <div>
          {/* <img
            src={require("../../img/PcWithDashSmall.svg")}
            alt="pc"
            className="computer-ani"
          /> */}
          <object
            data-sal="slide-left"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="1200"
            aria-label="dashboard"
            type="image/svg+xml"
            data={Computer}
            className="computer-ani"
          />
        </div>
      </div>
    </div>
  )
}
